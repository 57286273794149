<template>
  <b-row class="identifier-mapping">
    <h2 class="v2">{{this.$t('updater.step2')}}</h2>
    <b-col>
      <b-form-group label="Was soll als Identifier dienen?" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="radio-group-1"
          v-model="selectedOption"
          :aria-describedby="ariaDescribedby"
          name="flavour-1"
          @change="selectIdentifierOption()"
          >
          <b-form-radio value="Standard">Standard (Weingut & Weinname & Jahrgang & Flaschengröße)</b-form-radio>
          <b-form-radio value="Artikelnummer">Artikelnummer</b-form-radio>
          <b-form-radio value="Kassennotiz">Kassennotiz</b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <b-button @click="selectOtherIdentifier()" variant="secondary" class="mt-3 mb-3" >anderen Identifier wählen</b-button>
      <b-form-group v-if="selectedOption=='andere'" label="Was soll als Identifier dienen?" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="checkbox-group-2"
          v-model="selectedIdentifier"
          :aria-describedby="ariaDescribedby"
          name="flavour-2"
        >
          <b-form-checkbox v-for="column in possibleIdentifier" :value="column" :key="column.value" @change="changedValue(column)" style="display: flex; align-items: center;">
              {{ column }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
      <b-row class="mt-3 mb-3" v-if="notFoundIdentifier.length > 0">
        <b-col>
          <h4>Nicht gefundene Identifier zuordnen:</h4>
          <b-row class="mapping" v-for="column in notFoundIdentifier" :key="column">
            <b-col style="display: flex; align-items: center; margin-left: 3px;width: 100px;">
              {{ column }}
            </b-col>
            <b-col>
              <img class="arrow-icon" src="../../assets/img/icon-dropdown.svg">
            </b-col>
            <b-col style="width: 120px;">
              <b-form-select class="v2" v-model="rowMappingObject[column]" :options="csvColumns" @change="triggerReload = !triggerReload"></b-form-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-alert class="updater" :show="true">
        Ausgewählte Identifier: {{ Object.keys( rowMappingObject).map((key) => { return "Spalte " + rowMappingObject[key] + " als "+ key}).join(', ') }}
        </b-alert>
      </b-row>
    </b-col>
    <ButtonFooter :backButtonVisible="true" :okButtonDisabled="buttonDisabled"
           :backButtonLabel="this.$t('general.buttons.back')" :okButtonLabel="this.$t('general.buttons.forward')" :okAction="() => finishedMapping(rowMappingObject, selectedOption)" :backAction="handleBackFromStep2"/>
  </b-row>
</template>
<script>
import ButtonFooter from '@/components/modals/ButtonFooter.vue'

export default {
  name: "TargetMapping",
  components: {
    ButtonFooter
  },
  props: {
    csvColumns: Array,
    finishedMapping: Function,
    identifierMappingObject: Object,
    preSelectedOption: String
  },
  data() {
    return {
      selectedOption: 'Standard',
      selectedIdentifier: [],
      possibleIdentifier: [ 'Artikelnummer', 'Weingut', 'Weinname', 'Jahrgang', 'Region', 'Land', 'Farbe', 'Rebsorte', 'Flaschengröße'],
      notFoundIdentifier: [],
      rowMappingObject: {},
      triggerReload: false
    }
  },
  created() {
    console.log(this.identifierMappingObject, 'identifierMappingObject')
    this.rowMappingObject = this.identifierMappingObject;
    this.selectedOption = this.preSelectedOption;
    // only on first time use: select an option and use the selected keys as values in rowMappingObject
    if (Object.keys(this.identifierMappingObject).length === 0) {
      this.selectIdentifierBasedOnSelectedOption();
      this.useKeyAsValueForMapping();
    }
    this.addValuesFromMappingObject()
    this.checkIfCsvColumnsContainIdentifierAndPush();
  },
  methods: {
    handleBackFromStep2() {
      this.$emit('backFromStep2');
    },
    selectIdentifierOption() {
      this.selectIdentifierBasedOnSelectedOption();
      this.useKeyAsValueForMapping();
      this.notFoundIdentifier = [];
      this.checkIfCsvColumnsContainIdentifierAndPush();
    },
    selectIdentifierBasedOnSelectedOption(){
      if (this.selectedOption == 'Standard') {
        this.selectedIdentifier = [ 'Weingut',
          'Weinname',
          'Jahrgang',
          'Flaschengröße'];
      } else if (this.selectedOption == 'Artikelnummer') {
        this.selectedIdentifier = [ 'Artikelnummer'];
      } else if (this.selectedOption == 'Kassennotiz') {
        this.selectedIdentifier = [ 'Kassennotiz'];
      }
    },
    selectOtherIdentifier() {
      this.selectedOption = 'andere';
      this.selectedIdentifier = [];
      this.notFoundIdentifier = [];
      this.useKeyAsValueForMapping();
    },
    checkIfCsvColumnsContainIdentifierAndPush() {
      for (let identifier of this.selectedIdentifier) {
        if (!this.csvColumns.includes(identifier)) {
          this.notFoundIdentifier.push(identifier);
        }
      }
    },
    useKeyAsValueForMapping() {
      this.rowMappingObject = {}
      for (let key of this.selectedIdentifier) {
        if (this.csvColumns.includes(key)) {
          this.rowMappingObject[key] = key;
        } else {
          this.rowMappingObject[key] = '';
        }
      }
    },
    changedValue(value) {
      this.$nextTick(() => {
        console.log(value)
        console.log(this.selectedIdentifier)
        // was added now:
        if (this.selectedIdentifier.indexOf(value) > -1) {
          if (this.csvColumns.indexOf(value) == -1){
            this.notFoundIdentifier.push(value);
            this.rowMappingObject[value] = '';
          } else {
            this.rowMappingObject[value] = value;
          }
        } else {
          this.notFoundIdentifier = this.notFoundIdentifier.filter((item) => item != value);
          delete this.rowMappingObject[value];
        }
        this.triggerReload = !this.triggerReload;
      })
    },
    addValuesFromMappingObject(){
      var keys = Object.keys(this.rowMappingObject)
      for (var i = 0; i < keys.length; i++) {
        if (this.rowMappingObject[keys[i]] != null && this.rowMappingObject[keys[i]] != undefined && this.rowMappingObject[keys[i]] != '') {
          this.selectedIdentifier.push(keys[i]);
        }
      }
    }
  },
  computed: {

    buttonDisabled() {
      this.triggerReload 
      var keys = Object.keys(this.rowMappingObject)
      for (var i = 0; i < keys.length; i++) {
        if (this.rowMappingObject[keys[i]] == null || this.rowMappingObject[keys[i]] == undefined || this.rowMappingObject[keys[i]] == '') {
          return true;
        }
      }
      return this.selectedIdentifier.length == 0;
    }
  },
  watch: {
    /*
    identifierMappingObject: {
      handler: function (val) {
        console.log(val, 'identifierMappingObject')
        this.rowMappingObject = val;
        this.addValuesFromMappingObject()
      },
      deep: true
    },
    preSelectedOption: {
      handler: function (val) {
        console.log(val, 'preSelectedOption')
        this.selectedOption = val;
      },
      deep: true
    }*/
  }
}
</script>