<template>
  <div class="button-footer" :class="{ 'back-button-visible': backButtonVisible }">
    <b-button v-if="backButtonVisible" @click="backAction">
      {{ backButtonLabel }}
    </b-button>
    <b-button class="back" :disabled="okButtonDisabled" variant="success" @click="okAction">
      {{ okButtonLabel }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'ButtonFooter',
  props: {
    cancelButtonVisible: {
      type: Boolean,
      default: true
    },
    backButtonVisible: {
      type: Boolean,
      default: true
    },
    okButtonDisabled: {
      type: Boolean,
      default: false
    },
    cancelButtonLabel: {
      type: String,
      default: ''
    },
    backButtonLabel: {
      type: String,
      default: ''
    },
    okButtonLabel: {
      type: String,
      default: ''
    },
    cancelAction: {
      type: Function,
      default: () => {}
    },
    backAction: {
      type: Function,
      default: () => {}
    },
    okAction: {
      type: Function,
      default: () => {}
    }
  }
};
</script>

<style scoped>
.button-footer {
  display: flex;
  justify-content: flex-end;
  margin: var(--margin-l) 0;
}

.button-footer.back-button-visible {
  justify-content: space-between;
}
</style>